/* eslint-disable no-prototype-builtins */
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Params, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as netmask from 'netmask';
import { Subscription } from 'rxjs';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ConfigService } from '@core/config/config.service';
import {
  CONFIG_TEMPLATE_TYPES,
  IP_PATTERN,
  MASK_PATTERN,
  MERAKI_CONFIG_TEMPLATE_TYPES,
  ND_FIREWALL_CLUSTER_OPTIONS, ND_MERAKI_MX_CLUSTER_OPTIONS,
  NetworkDeviceClusterMode
} from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import {
  IFilters,
  IGenericListOptions, IMerakiOrganization,
  IMerakiTemplate,
  IOperatorLine,
  ISelectOption,
  IZabbixProxy,
  MerakiTypeEnum
} from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { ProviHistoriesAttachmentsModalComponent } from '@views/provi-histories/provi-histories-attachments-modal.component';
import { NetworkDevicesProviModalComponent } from './network-devices-provi-modal.component';
import { ArgosEventMutesModalComponent } from '../argos-event-mutes/argos-event-mutes-modal.component';


@Component({
  selector: 'app-network-devices-detail',
  templateUrl: './network-devices-detail.component.html',
  styles: []
})
export class NetworkDevicesDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;

  public readonly MerakiTypeEnum = MerakiTypeEnum;
  public readonly ClusterMode = NetworkDeviceClusterMode;
  public readonly typeOptions = {...CONFIG_TEMPLATE_TYPES, ...MERAKI_CONFIG_TEMPLATE_TYPES};
  public readonly firewallClusterOptions: ISelectOption[] = ND_FIREWALL_CLUSTER_OPTIONS;
  public readonly merakiMXClusterOptions: ISelectOption[] = ND_MERAKI_MX_CLUSTER_OPTIONS;
  public readonly merakiTypes = Object.keys(MERAKI_CONFIG_TEMPLATE_TYPES);
  public readonly ipPattern: RegExp = IP_PATTERN;
  public readonly maskPattern: RegExp = MASK_PATTERN;
  public readonly formatAPNName: RegExp = /^[a-zA-Z0-9\-.]+$/;

  private defaultBreadcrumbsData = [{label: 'Équipements réseaux', routerLink: '/network-devices/list'}];
  // The viewName is used to build a key for the user preferences
  public readonly viewName = 'network-devices';
  public relatedND = '';
  public commentsCount: number;
  public ipManagmentCollapsed: boolean;
  public ipWan2Collapsed: boolean;
  public merakiOrganization: IMerakiOrganization;
  public merakiTemplate: IMerakiTemplate;
  public isNameEditable: boolean;
  public showMerakiInfoOrga: boolean;
  public showMerakiInfoTemplate: boolean;
  public merakiSwitchProfileFieldFilters: IFilters;
  public consistencyError = {major: '', minor: ''};
  public gatewayConsistent: boolean;
  public netmask: Object;
  public zabbixBaseUrl: string;
  public zabbixLoading: boolean;
  public firstSimCardFilters: IFilters = this._createSimCardFilters();
  public secondSimCardFilters: IFilters = this._createSimCardFilters();

  // we need to map api.zabbix_groups to app-lines-detail-zabbix-field
  public api: ApiProvitoolService['network_devices'];

  public ipManagementCollapsed = false;
  public supervisionCollapsed = false;
  public simOneCollapsed = false;
  public simTwoCollapsed = false;
  public SimOneOperatorLineCode = '';
  public SimTwoOperatorLineCode = '';
  public linesOpt: IGenericListOptions;
  public equipmentOpt: IGenericListOptions;
  public secondaryEquipmentOpt: IGenericListOptions;
  public equipmentsHistoryOpt: IGenericListOptions;
  public argosEventMutesOpt: IGenericListOptions;

  public existingProxies: string[];
  public initialOfferType: string | undefined;
  public technology: string | undefined;

  private searchParams: Params;
  private merakiOrgaNamingConvention: string;
  private merakiOrgaNamingConventionCase: string;
  private zabbixProxies: IZabbixProxy[];

  private signalSubscriptions: Subscription[] = [];

  public get canSynchroZabbix(): boolean {
    // ignore types that are not firewall or l2switch, assume they don't have requirements for Zabbix
    if (!['firewall', 'l2switch'].includes(this.detail.type))  {
      return true;
    }

    if (this.detail.type === 'firewall') {
      // without equipment, synch is not possible
      if (!this.detail.equipment || (this.detail.cluster_mode !== NetworkDeviceClusterMode.None && !this.detail.secondary_equipment)) {
        return false;
      }

      // labels are required for synch
      if (!this.detail.printing_label || !this.detail.monitoring_label) {
        return false;
      }

      // labels are required for synch
      const missingMinorLabel: boolean = !this.detail.minor_printing_label || !this.detail.minor_monitoring_label;
      if (this.detail.cluster_mode === NetworkDeviceClusterMode.IndividualIp && missingMinorLabel) {
        return false;
      }
    }

    if (this.detail.type === 'l2switch') {
      // without equipment, synch is not possible
      if (!this.detail.equipment) {
        return false;
      }
    }

    return true;
  }

  constructor(
    private configService: ConfigService,
    private apiProvitool: ApiProvitoolService,
    private apiShiva: ApiShivaService,
    private ngbModal: NgbModal,
    private wcmModalsService: WcmModalsService,
    public injector: Injector,
    private router: Router,
    private promisesService: PromisesService,
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {type: 'router', cluster_mode: NetworkDeviceClusterMode.None, is_classic_router: false};
    // Api used for fetch, update and create
    this.api = this.apiProvitool.network_devices;
    this.liveUpdateChannel = 'networkdevice';
    this.gatewayConsistent = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._initSubscriptions();
    this.searchParams = this.queryString.getSearchParams();
    this.zabbixBaseUrl = this.configService.zabbixBaseUrl + 'hostinventories.php?hostid={}';
    if (!this.pk) {
      // checking if an entity code was given through the url parameter as default value
      if (this.searchParams.entity) {
        this.apiShiva.entities.detail(this.searchParams.entity)
          .then(res => {
            this.detail.entity = res;
            this._getMerakiInfo(res['code']);
          })
          .catch(err => Promise.reject(err))
          .finally(() => this.queryString.setSearchParams({entity: null}));
      }
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) { return; }
    // "may" prevent executing twice creation when some jackass hit the button faster than lightning
    if (this.loading) {return;}

    this.loading = true;
    let promise;
    const disableError = true;

    const isNew = !this.detail.code;

    if (isNew) {
      promise = this.api.create(this.detail);
    } else {
      // removing the object attributes that we use locally for the edition
      let payload = {...this.detail};
      payload = omit(payload, 'zabbixHostGroupsPrimary', 'zabbixHostGroupsSecondary', 'zabbixHostTemplatesPrimary', 'zabbixHostTemplatesSecondary');
      promise = this.api.update(this.detail.code, payload, disableError);
    }

    promise.then((res) => {
      const oldZabbix = {
        zabbixHostGroupsPrimary: this.detail.zabbixHostGroupsPrimary,
        zabbixHostGroupsSecondary: this.detail.zabbixHostGroupsSecondary,
        zabbixHostTemplatesPrimary: this.detail.zabbixHostTemplatesPrimary,
        zabbixHostTemplatesSecondary: this.detail.zabbixHostTemplatesSecondary,
      };
      this.detail = Object.assign(res, oldZabbix);
      if (isNew) {
        this.pk = this.detail.code;
        // it was a creation
        this.signalsService.broadcast('network-devices:create', this.detail.code);
        this._initTabs();
      }

      if (['firewall', 'l2switch'].includes(this.detail.type)) {
        // handle primary groups
        this._saveAndFetchZabbixHostGroups();
        // handle primary templates
        this._saveAndFetchZabbixHostTemplates(true);
        // handle secondary templates
        this._saveAndFetchZabbixHostTemplates(false);
      }
      this._updateBreadcrumbs();

      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.signalsService.broadcast('model-history-list-refresh');
      this.detailSaved.emit(this.detail);
      this.initialOfferType = this.detail.operator_line?.offer?.type;
    })
    .catch((err) => {
      if (err instanceof WaycomHttpErrorResponse) {
        if (err.getFirstErrorMessage() === 'ONE_SIM_MUST_BE_PRIMARY') {
          this.toastr.error(`Une carte sim doit être considéré comme primaire.`);
          return;
        } else if (err.getFirstErrorMessage() === 'ONLY_ONE_SIM_MUST_BE_PRIMARY') {
          this.toastr.error(`Une seule carte sim peut être considéré comme primaire.`);
          return;
        }
      }
      Promise.reject(err);
    })
    .finally(() => {
      this.loading = false;
      this.signalsService.broadcast('model-history-list-refresh');
    });
  }

  public onStateUpdate(): void {
    this._fetch();
  }

  public onChangeType(): void {
    if (this.merakiTypes.includes(this.detail.type)) {
      delete this.detail.config_template;
      delete this.detail.operator_line;
      if (this.merakiOrgaNamingConvention) {
        this._computeNameFromConvention();
      }
    }

    if (this.detail.type === MerakiTypeEnum.MG) {
      this.detail.sim_2_apn_default = true;
      this.detail.sim_1_primary = true;
      this.detail.sim_1_ip_type = 'IPv4';
    }

    if (!this.zabbixProxies) {
      this._fetchZabbixProxies();
    }
  }

  public onProxyUpdate(): void {
    // Bind a proxy label to a proxy id
    const zabbixProxy = this.zabbixProxies.find((proxy) => proxy.host === this.detail.proxy_monitoring_name);
    this.detail.proxy_monitoring_id = zabbixProxy?.proxyid;
  }

  public onChangeClusterNatCb(): void {
    this.detail.secondary_equipment = null;
    if (this.detail.cluster_mode !== NetworkDeviceClusterMode.None) {
      this._computeNameFromConvention();
    }
  }

  public onChangeFirewallClusterNatCb(): void {
    this.detail.secondary_equipment = null;
    this.detail.minor_address = null;
    this.detail.minor_management_url = null;
    this.detail.minor_printing_label = null;
    this.detail.minor_monitoring_label = null;
  }

  public onAddressChanged(address: string, fieldname: string): void {
    this.detail[fieldname] = address ? 'https://' + address + '/' : this.detail[fieldname];
  }

  public onChangeAPNDefaultOne(): void {
    this.detail.sim_1_ip_type = 'IPv4';
    this.detail.sim_1_apn_name = null;
  }

  public onChangeAPNDefaultTwo(): void {
    this.detail.sim_2_ip_type = 'IPv4';
    this.detail.sim_2_apn_name = null;
  }

  public getModelTypeLabel(type: string): string {
    const modelTypeLabel = {
      meraki_mr: 'Borne WiFi',
      meraki_ms: 'Switch',
      meraki_mx: 'Firewall',
      meraki_mg: 'Routeur',
      router: 'Routeur',
      l2switch: 'Switch',
    };
    return modelTypeLabel[type];
  }

  public updateFirstSimFilters(): void {
    this.firstSimCardFilters = this._createSimCardFilters(this.detail.second_sim_card_equipment?.code);
  }

  public updateSecondSimFilters(): void {
    this.secondSimCardFilters = this._createSimCardFilters(this.detail.first_sim_card_equipment?.code);
  }

  private _createSimCardFilters(simCardEquipmentCode?: string | undefined): IFilters {
    return {
      model__category__label: 'sim-card',
      first_sim_card_device__isnull: true,
      second_sim_card_device__isnull: true,
      code__nin: simCardEquipmentCode,
    };
  }

  private _handleZabbixErrors(err: unknown, code: string): void {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.getFirstErrorMessage() === 'NETWORK_DEVICE_DOES_NOT_HAVE_ZABBIX_ID') {
        this.toastr.error(`Cet équipement ${code} n'as pas d'identifiant Zabbix.`);
        return;
      } else if (err.getFirstErrorMessage() === 'ERROR_FOUND_DURING_PROCESS') {
        this.toastr.error(`Erreur lors de l'ajout des groupes Zabbix, cause : ${err.context['error']}.`);
        return;
      }
    }
    Promise.reject(err);
  }

  private _saveAndFetchZabbixHostGroups(): void {
    let groupKeys: string[] = ['zabbixHostGroupsPrimary'];
    if (this.detail.cluster_mode === NetworkDeviceClusterMode.IndividualIp) {
      groupKeys = ['zabbixHostGroupsPrimary', 'zabbixHostGroupsSecondary'];
    }

    const has_zabbix_ids = this.detail.zabbix_id || this.detail.minor_zabbix_id;
    if (!has_zabbix_ids || !['firewall', 'l2switch'].includes(this.detail.type)) {
      return;
    }

    this.zabbixLoading = true;
    const addGroups = {
      zabbixHostGroupsPrimary: [],
      zabbixHostGroupsSecondary: [],
    };
    const removeGroups = {
      zabbixHostGroupsPrimary: [],
      zabbixHostGroupsSecondary: [],
    };

    // 1 - list groups to add
    for (const groupKey of groupKeys) {
      if (!this.backup.hasOwnProperty(groupKey) || !this.detail[groupKey]) {
        continue;
      }
      for (const hostGroup of this.detail[groupKey]) {
        if (!this.backup[groupKey].includes(hostGroup)) {
          addGroups[groupKey].push(hostGroup);
        }
      }
    }

    // 2 - list groups to remove
    for (const groupKey of groupKeys) {
      if (!this.backup.hasOwnProperty(groupKey) || !this.detail[groupKey]) {
        continue;
      }
      for (const hostGroup of this.backup[groupKey]) {
        if (!this.detail[groupKey].includes(hostGroup)) {
          removeGroups[groupKey].push(hostGroup);
        }
      }
    }

    // 3 - add host
    if (addGroups['zabbixHostGroupsPrimary'].length || addGroups['zabbixHostGroupsSecondary'].length) {
      const payload = {
        host_groups: []
      };

      for (const groupKey of groupKeys) {
        if (!addGroups[groupKey].length) {
          continue;
        }

        const is_primary = groupKey === 'zabbixHostGroupsPrimary';
        payload['host_groups'].push({
          is_primary: is_primary,
          group_names: addGroups[groupKey]
        });
      }
      this.api.add_zabbix_host_groups(this.detail.code, payload)
      .then(() => this.toastr.success(`Groupe(s) Zabbix ajouté(s) de l'équipement réseau.`))
      .catch((err) => this._handleZabbixErrors(err, this.detail.code));
    }

    // 4 - remove host
    if (removeGroups['zabbixHostGroupsPrimary'].length || removeGroups['zabbixHostGroupsSecondary'].length) {
      const payload = {
        host_groups: []
      };

      for (const groupKey of groupKeys) {
        if (!removeGroups[groupKey].length) {
          continue;
        }

        const is_primary = groupKey === 'zabbixHostGroupsPrimary';
        payload['host_groups'].push({
          is_primary: is_primary,
          group_names: removeGroups[groupKey]
        });
      }
      this.api.remove_zabbix_host_groups(this.detail.code, payload)
      .then(() => this.toastr.success(`Groupe(s) Zabbix retiré(s) de l'équipement réseau.`))
      .catch((err) => this._handleZabbixErrors(err, this.detail.code));
    }
  }

  // Note that the behavior of Host Template should be adapted to reflects the single action code refactor introduced
  // by story #860qh98rb
  // This should be refactored with the above, handling both groups and templates case.
  // This behavior also exists within line detail Component, so maybe we should extract this and create
  // a top level Component or service ?
  private _saveAndFetchZabbixHostTemplates(is_primary: boolean): void {
    const zabbix_id = is_primary ? this.detail.zabbix_id : this.detail.minor_zabbix_id;
    const hostTemplateKey = is_primary ? 'zabbixHostTemplatesPrimary' : 'zabbixHostTemplatesSecondary';

    if (!zabbix_id || !['firewall', 'l2switch'].includes(this.detail.type)) {
      return;
    }

    this.zabbixLoading = true;
    const addTemplates = [];
    const removeTemplates = [];

    // 1 - list templates to add
    for (const hostTemplate of this.detail[hostTemplateKey]) {
      if (!this.backup[hostTemplateKey].includes(hostTemplate)) {
        addTemplates.push(hostTemplate);
      }
    }
    // 2 - list templates to remove
    for (const hostTemplate of this.backup[hostTemplateKey]) {
      if (!this.detail[hostTemplateKey].includes(hostTemplate)) {
        removeTemplates.push(hostTemplate);
      }
    }

    if (!addTemplates.length && !removeTemplates.length) {
      this._initZabbixHostTemplates();
      this.zabbixLoading = false;
      return;
    } else if (addTemplates.length) {
      this.api.add_zabbix_host_templates(this.detail.code, addTemplates, is_primary)
        .then(() => this.toastr.success(`Template(s) Zabbix "${addTemplates}" ajouté(s) sur la ligne.`))
        .catch((err) => this._handleZabbixErrors(err, this.detail.code))
        .finally(() => {
          if (removeTemplates.length) {
            this._removeZabbixHostTemplates(removeTemplates, is_primary);
          } else {
            this._initZabbixHostTemplates();
            this.zabbixLoading = false;
          }
        });
    } else if (removeTemplates.length) {
      this._removeZabbixHostTemplates(removeTemplates, is_primary);
    }
  }

  // This should be refactored with the above, handling both groups and templates case.
  // This behavior also exists within line detail Component, so maybe we should extract this and create
  // a top level Component or service ?
  private _removeZabbixHostTemplates(removeGroups: string[], is_primary: boolean): void {
    this.api.remove_zabbix_host_templates(this.detail.code, removeGroups, is_primary)
    .then(() => this.toastr.success(`Template(s) Zabbix "${removeGroups}" retiré(s) de la ligne.`))
    .catch((err) => this._handleZabbixErrors(err, this.detail.code))
    .finally(() => {
      this._initZabbixHostTemplates();
      this.zabbixLoading = false;
    });
  }

  private _handleSaveError(err): void {
    if (err instanceof WaycomHttpErrorResponse) {
      switch (err.getFirstErrorMessage()) {
        case 'WRONG_OL_STATE':
          this.toastr.error(`Le lien opérateur choisi n'est pas actif.`);
          return;
        case 'OL_STATE_IS_NOT_ACTIVE':
          this.toastr.error(
            `Le status du lien opérateur n'est pas correct.`,
            `Impossible d'affecter le lien opérateur sur cet équipement réseau.`
          );
          return;
        case 'COULD_NOT_CHANGE_OPERATOR_LINE':
          this.toastr.error(
            `Le nouveau lien opérateur n'a pas une offre du même type que le précédent (PPP, interco)`,
            `Impossible de changer le lien opérateur sur cet équipement réseau.`
          );
          return;
        case 'DUPLICATE':
          if (err.context && err.context.fields && err.context.fields.includes('number')) {
            this.toastr.error(`Numéro d'équipement réseau déjà utilisé pour cette entité et ce type.`);
          }
          return;
        case 'MERAKI_MX_ALREADY_EXISTS_ON_SITE':
          this.toastr.error('Un seul meraki_mx peut être affecté par site.');
          return;
        case 'FROM_1_TO_254':
          this.toastr.error(`Le numéro d'équipement renseigné n'est pas compris dans l'intervalle [1, 254]`);
          return;
      }
    }
    // bubble up error to generic handler
    Promise.reject(err);
  }

  public createNewLine(): void {
    this.router.navigateByUrl(`/lines/detail/?network_device=${this.detail.code}`);
  }

  public checkNumber(): void {
    this.detail.invalidNumber = this.detail.number !== null && (this.detail.number <= 0 || this.detail.number > 254) ;
    if (!this.merakiOrgaNamingConvention) {
      this._getMerakiInfo(this.detail.entity.code)
        .then(() => this._computeNameFromConvention())
        .catch(err => Promise.reject(err));
    } else {
      this._computeNameFromConvention();
    }
  }

  public confirmDelete(): void {
    const msgTitle = `Suppression d'un équipement réseau`;
    const msgBody = `
      La suppression de ce ND entrainera la suppression des subnets et lignes réseaux associés.
      <br><br>Confirmez-vous la suppression de cet équipement réseau ?
    `;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler')
      .then(() => this._delete())
      .catch(() => {});
  }

  /**
   * show the modal for adding an ArgosEventMute (fenêtre de maintenance)
   */
  public addMaintenance(): void {

    const modal = this.ngbModal.open(
      ArgosEventMutesModalComponent, {backdrop: 'static', size: 'lg'}
    );

    modal.componentInstance.mode = 'edition';
    modal.componentInstance.networkDevice = this.detail;
    modal.componentInstance.contentType = 'detail';

    modal.result
      .then(() => this.signalsService.broadcast('argos-event-mutes-list-refresh'))
      .catch(err => Promise.reject(err));
  }

  public openProviModal(mode: string): void {
    const modal = this.ngbModal.open(
      NetworkDevicesProviModalComponent,
      {windowClass: 'wcm-stacked-modal-lvl-0', backdropClass: 'wcm-stacked-modal-lvl-0-backdrop'}
    );
    modal.componentInstance.mode = mode;
    modal.componentInstance.configTemplatePk = this.detail.config_template.id;
    modal.componentInstance.networkDevicePk = this.detail.code;
  }

  public openProviHistoriesAttachmentsModal(networkDeviceCode: string, attachmentId: number, attachmentType: string): void {
    const modal = this.ngbModal.open(ProviHistoriesAttachmentsModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.attachmentId = attachmentId;
    modal.componentInstance.networkDeviceCode = networkDeviceCode;
    modal.componentInstance.attachmentType = attachmentType;
  }

  public verifyConsistencyWithIpAndMask(minor: boolean = false, fieldModified: string = 'noGateway'): void {
    // do not check for dhcp
    if (this.detail.dhcp === true && (!this.detail.minor || this.detail.minor_dhcp === true)) {
      this.gatewayConsistent = true;
      return;
    }

    // this handles also minor cases not to have 2 very similar functions
    this.gatewayConsistent = false;
    let bitMask = this.detail.mask;
    let address = this.detail.address;
    let gateway = this.detail.gateway_address ? this.detail.gateway_address: '';
    if (minor) {
      bitMask = this.detail.minor_mask;
      address = this.detail.minor_address;
      gateway = this.detail.minor_gateway_address ? this.detail.minor_gateway_address: '';
    }
    // these are controls not to create Netmask instance with wrong value
    if ((!bitMask || !address) && fieldModified === 'gateway') {
      if (minor) {
        this.consistencyError['minor'] = `Complétez les champs "masque" et "IP" en priorité.`;
      } else {
        this.consistencyError['major'] = `Complétez les champs "masque" et "IP" en priorité.`;
      }
      return;
    }else if (!gateway.match(this.ipPattern) || !address.match(this.ipPattern)) {
      return;
    } else {
      const network = new netmask.Netmask(`${address}/${bitMask}`);

      const octets = gateway.split('.');
      this.gatewayConsistent = false;
      // complete ip address must be given to netmask instance not to raise error
      if (octets.length === 4 && octets.reverse()[0]) {
        if (network.contains(gateway)) {
          if (minor) {
            this.consistencyError['minor'] = ``;
          } else {
            this.consistencyError['major'] = ``;
          }
          this.gatewayConsistent = true;
        } else {
          if (minor) {
            this.consistencyError['minor'] = `Cette adresse n'est pas dans le réseau défini par l'ip et le masque cidr.`;
          } else {
            this.consistencyError['major'] = `Cette adresse n'est pas dans le réseau défini par l'ip et le masque cidr.`;
          }
        }
      }
    }
  }

  public updateEquipmentFilters(): void {
    this.equipmentOpt = {filters: {}};
    this.secondaryEquipmentOpt = {filters: {}};

    if (this.detail.equipment) {
     this.secondaryEquipmentOpt.filters = {
      id__nin: this.detail.equipment.id,
      model__brand__name: this.detail.equipment.model.brand.name
     };
    }
    if (this.detail.secondary_equipment) {
      this.equipmentOpt.filters = {
       id__nin: this.detail.secondary_equipment.id,
       model__brand__name: this.detail.secondary_equipment.model.brand.name
      };
    }
  }

  public onChangeOperatorLine(ol: IOperatorLine) {
    // business rules : 1 OL can have more than 1 ND but we have to prevent user.
    if (ol) {
      this.apiProvitool.operator_lines.list_related_active_nd(ol.code)
        .then((res: {relatedND: string[]}) => {
          this.relatedND = res['related_nd'].length ? res['related_nd'] : null;
        });
    }
  }

  public onChangeOperatorLineSimOne(ol: IOperatorLine ) {
    if (ol) {
      this.apiProvitool.operator_lines.list_related_active_nd(ol.code)
        .then((res: {relatedND: string[]}) => {
          this.relatedND = res['related_nd'].length ? res['related_nd'] : null;
        });
      this.SimOneOperatorLineCode = ol.code;
    } else {
      this.SimOneOperatorLineCode = '';
    }
  }

  public onChangeOperatorLineSimTwo(ol: IOperatorLine ) {
    if (ol) {
      this.apiProvitool.operator_lines.list_related_active_nd(ol.code)
        .then((res: {relatedND: string[]}) => {
          this.relatedND = res['related_nd'].length ? res['related_nd'] : null;
        });
        this.SimTwoOperatorLineCode = ol.code;
    } else {
      this.SimTwoOperatorLineCode = '';
    }
  }

  private redirectAfterDelete(): void {
    // active_tab=20 is the provi-tab on entity detail
    this.router.navigateByUrl(`/entities/detail/${this.detail.entity.code}?active_tab=20`);
  }

  private _delete(): void {
    this.loading = true;
    this.api.delete(this.detail.code)
      .then(() => setTimeout(() => this.redirectAfterDelete()))
      .catch(err => {
        this.toastr.error('Erreur lors de la suppression, veuillez essayer à nouveau.');
        Promise.reject(err);})
      .finally(() => this.loading = false);
  }

  protected _fetch(): void {
    this.loading = true;
    this.zabbixLoading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this.updateEquipmentFilters();
        this.updateFirstSimFilters();
        this.updateSecondSimFilters();
        this._updateBreadcrumbs();
        this._initTabs();
        this._fetchZabbixProxies();
        if (['firewall', 'l2switch'].includes(this.detail.type)) {
          this._initZabbixHostGroups();
          this._initZabbixHostTemplates();
        }
        this.initialOfferType = this.detail.operator_line?.offer?.type;
        this.technology = this.detail.operator_line?.offer?.technology?.name;
        if (res['entity'] && this.merakiTypes.includes(res['type'])) {
          this._getMerakiInfo(res['entity']['code']);
        }})
      .catch(err => Promise.reject(err))
      .finally(() => {
        this.loading = false;
        this.zabbixLoading = false;
      });
  }

  private _fetchZabbixProxies(): void {
    if (['firewall', 'l2switch'].includes(this.detail.type)) {
      this.api.zabbix_proxies()
        .then((res) => {
          this.zabbixProxies = res['proxies'];
          this.existingProxies = this.zabbixProxies.map((proxy) => proxy.host);
        });
    }
  }

  private _initTabs(): void {
    // If any tab filter must be initialized, it's done here
    this.linesOpt = {
      filters: {network_device__code: this.detail.code, serializer: 'with_status'},
      disabledButtons: {create: false},
      disabledColumns: {
        is_active: true,
        network_device__code: true,
        network_device__entity__name_or_code: true,
        is_up: false
      }
    };

    this.equipmentsHistoryOpt = {
      filters: {network_device__code: this.detail.code},
    };

    this.argosEventMutesOpt = {
      filters: {
        network_device__code__inherit: this.detail.code,
        ordering: '-date_from'
      }
    };

  }

  private _initZabbixHostGroups(): void {
    this.api.list_zabbix_host_group(this.detail.code, true).then((res => {
      if (res['groups']) {
        this.detail.zabbixHostGroupsPrimary = res['groups'].map((group) => group.name);
      }
    }));

    if (this.detail.minor_zabbix_id) {
      this.api.list_zabbix_host_group(this.detail.code, false).then((res => {
        if (res['groups']) {
          this.detail.zabbixHostGroupsSecondary = res['groups'].map((group) => group.name);
        }
      }));
    }
  }

  private _initZabbixHostTemplates(): void {
    this.api.list_zabbix_host_template(this.detail.code, true).then((res => {
      if (res['templates']) {
        this.detail.zabbixHostTemplatesPrimary = res['templates'].map((template) => template.name);
      }
    }));

    if (this.detail.minor_zabbix_id) {
      this.api.list_zabbix_host_template(this.detail.code, false).then((res => {
        if (res['templates']) {
          this.detail.zabbixHostTemplatesSecondary = res['templates'].map((template) => template.name);
        }
      }));
    }
  }

  /**
   * initialise the various signal subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
   private _initSubscriptions(): void {
    const sub = this.signalsService.subscribe('workflow:updated:network-device', () => this.signalsService.broadcast('workflow-histories-list-refresh'));
    this.signalSubscriptions.push(sub);

    const vrf_subnet_ip_added = this.signalsService.subscribe('vrf-subnet-ip-added', () => this._fetch());
    this.signalSubscriptions.push(vrf_subnet_ip_added);

    const commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.signalSubscriptions.push(commentsCountSubscription);
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.entity) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: `/entities/detail/${this.detail.entity.code}`
      });
    }
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/network-devices/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _getMerakiInfo(entityCode: string): Promise<void> {
    const deferred = this.promisesService.defer();
    this.apiProvitool.meraki_networks.list({entity__code: entityCode})
      .then(res => {
        this.isNameEditable = (res.count === 0 || res.results[0].is_auto === false);
        this.showMerakiInfoOrga = !(res.count === 0);
        this.showMerakiInfoTemplate = !(res.count === 0 || res.results[0].is_auto === false);
        if ( res.count > 0 ) {
          this.merakiOrganization = res.results[0].organization;
          this.merakiTemplate = res.results[0].template;
          // needed to create and update name and spare_name when number change
          this.merakiOrgaNamingConvention = res.results[0].organization.network_device_naming_convention;
          this.merakiOrgaNamingConventionCase = res.results[0].organization.network_device_naming_convention_case;
          if (!this.pk) {
            this.detail.dns_primary = res.results[0].organization.dns_primary;
            this.detail.dns_secondary = res.results[0].organization.dns_secondary;
          }
          this.merakiSwitchProfileFieldFilters = {
            template__organization__code: res.results[0].organization.code,
            // If no template we need to set a value so that we don't actually ignore the template and return invalid results
            template__id: res.results[0].template?.id || 0,
          };
        }
        deferred.resolve();
      })
      .catch(err => Promise.reject(err));
    return deferred.promise;
  }

  private _computeNameFromConvention(): void {
    this.loading = true;
    const payload = {
      entity_code: this.detail.entity?.code,
      network_device_type: this.detail.type,
      naming_convention: this.merakiOrgaNamingConvention,
      naming_convention_case: this.merakiOrgaNamingConventionCase,
      network_device_number: this.detail.number
    };

    this.apiProvitool.network_devices.convention_name(payload)
      .then(res => {
        this.detail.spare_name = res.spare_convention_name ? res.spare_convention_name: null;
        this.detail.name = res.convention_name;})
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  public cleanHiddenFields(minor: boolean = false): void {
    const minorPrefix = minor ? 'minor_' : '';

    delete this.detail[(minorPrefix + 'address')];
    delete this.detail[(minorPrefix + 'mask')];
    delete this.detail[(minorPrefix + 'gateway_address')];
    delete this.detail[(minorPrefix + 'dns_primary')];
    delete this.detail[(minorPrefix + 'dns_secondary')];
  }

  public updateConf() {
    if (this.detail.is_classic_router) {
      this.apiProvitool.config_templates.detail(256)
      .then((res) => {
        this.detail.config_template = res;
      }, () => {}).finally(() => {
        this.loading = false;
      });
    } else {
      delete this.detail.config_template;
    }
  }

}
