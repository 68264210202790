import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { defer, from, of, ReplaySubject, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { times } from 'underscore';

import { EQP_LOCATIONS, EQP_OWNERS, EQUIPMENT_LOCATION_WAREHOUSES, EquipmentLocationEnum, ZONES_OPTIONS } from '@core/constants';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { isArray } from '@core/helpers';
import {
  IEntity,
  IEquipment,
  IEquipmentLocation,
  IEquipmentRecord,
  IFilters,
  IGenericListOptions,
  IListResult,
  ITinyLogisticsSite
} from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { EquipmentsService } from './equipments.service';
import { EquipmentsDetailNoSerialNumberModalComponent } from './equipments-detail-no-serial-number-modal.component';

@Component({
  selector: 'app-equipments-detail',
  templateUrl: './equipments-detail.component.html',
  styles: []
})
export class EquipmentsDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public disabledButtonCreateAdd: boolean;
  @Input() public disableCreateRedirection: boolean;

  public readonly EquipmentLocationEnum = EquipmentLocationEnum;

  private defaultBreadcrumbsData = [{label: 'Équipements', routerLink: '/equipments/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public readonly viewName: string = 'equipments';
  public reservedForList: IGenericListOptions;
  private api: ApiShivaService['equipments'];

  public detail: IEquipment;

  public readonly locationOptions: Record<string, string> = EQP_LOCATIONS;
  public readonly warehouseOptions: Record<string, string> = EQUIPMENT_LOCATION_WAREHOUSES;

  // Properties used to hold the filters for the equipment location options
  public logisticsSite: ITinyLogisticsSite = null;
  public warehouse: string = null;

  // Either the fetch was triggered by the user when selecting a different filter value,
  // or we want to initialise the list on equipment fetch
  private readonly equipmentLocationsFetchRequired$: Subject<void> = new Subject<void>();
  // Observable to fetch the list of equipment locations matching the selected filters
  public readonly equipmentLocations$: ReplaySubject<IEquipmentLocation[]> = new ReplaySubject<IEquipmentLocation[]>(1);

  public macPattern: RegExp = /^([0-9A-Fa-f]{2}(:|-|\s|)){5}[0-9A-Fa-f]{2}$/;
  public counts: Record<string, any> = {
    status: null,
    updated: false,
    checked_eqp: 0,
    available_eqp: 0,
    ordered_eqp: 0,
    current_equipment_in_order: 0, // number of equip for the current order
  };

  public commentsCount: number;
  public equipementModelFilters: IFilters;
  public prefix: string;
  public recordList: IEquipmentRecord[] = [];
  public zones: string[] = [];


  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private equipmentsService: EquipmentsService,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {location: EquipmentLocationEnum.Stock} as IEquipment;
    // Api used for fetch, update and create
    this.api = this.apiShiva.equipments;

    // This enables the live update (websocket)
    this.liveUpdateChannel = 'equipment';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._initSubscriptions();
    this.reservedForList = {
      filters: {
        is_customer: true,
        parent__isnull: true
      },
      disabledButtons: {
        type: true
      }
    };
    this.zones = ZONES_OPTIONS;
  }

  public onLogisticsSiteChanged(): void {
    this.equipmentLocationsFetchRequired$.next();
    this._resetEquipmentLocation();
  }

  public onWarehouseChanged(): void {
    this.equipmentLocationsFetchRequired$.next();
    this._resetEquipmentLocation();
  }

  public compareEquipmentLocation(first: IEquipmentLocation, second: IEquipmentLocation): boolean {
    return first?.id === second?.id;
  }

  public onChangeLocation(location: keyof typeof EQP_LOCATIONS): void {
    this.detail.entity = null;
    this.detail.zone = location !== EquipmentLocationEnum.Stock ? '' : this.detail.zone;
  }

  public removePrefix(): void {
    this.detail.serial_number = this.equipmentsService.snRemovePrefix(this.detail.serial_number, this.prefix);
    this.prefix = null;
  }

  public addNoSerialNumberEquipmentModal(): void {
    this.loading = true;
    if (this.detail.id || !this.hasPermissions('Wira:EqpCanManageNoSN')) {
      return;
    }
    const modal = this.ngbModal.open(EquipmentsDetailNoSerialNumberModalComponent, {backdrop: 'static', size: 'md'});
    modal.result
      .then(
        (quantity: number) => this._generateRandomSN(quantity),
        () => {}
      )
      .finally(() => this.loading = false);
  }

  public onProviderOrderUpdate(): void {
    this._updateEquipmentModelFilters();
    this.counts.updated = false;
    this._fetchAvailableEquipmentCount();

    if (!this.detail?.provider_order) {
      return;
    }

    this.detail.owner = this.detail?.provider_order?.buying_company?.code;
    // Checking only the first quote to get the quote entity
    const providerOrderQuotes = this.detail.provider_order.quotes || [];
    const firstQuote = providerOrderQuotes[0] || {};
    this.detail.reserved_for = firstQuote.entity || null;
  }

  public onEquipmentModelUpdate(): void {
    this.counts.updated = false;
    if (!this.detail.model || !this.detail.provider_order) {
      return;
    }
    this._fetchAvailableEquipmentCount();

    // We fetch one of the accounting eqp of this provider order and this equipment model to get its unit price
    const filters = {
      limit: 1,
      provider_order__order_number: this.detail.provider_order.order_number,
      equipment_model_mapping__equipment_model__id: this.detail.model.id
    };

    this.apiShiva.accounting_equipments.list(filters)
      .then((res) => {
        if (res['count'] > 0) {
          this.detail.price_untaxed = res['results'][0].unit_price;
          this.counts.current_equipment_in_order = res['results'][0].current_equipment_in_order;
          this.counts.checked_eqp = this.counts.current_equipment_in_order;
          // change the color of the displayed message
          this._changeCountStatus();
        }
      })
      .catch(() => this.toastr.error(`Erreur lors de la récupération du prix unitaire de l'équipement comptable.`));
  }

  public onSerialNumberUpdate(): void {
    // Check if serial numbers are given by the user, and if there are, count them
    let contentTextarea: string[] = this.detail.serial_number.split(/\r?\n/);
    contentTextarea = contentTextarea.filter((serialNumber: string) => serialNumber.length >= 1); // ignore serialNumber = ""
    this.counts.checked_eqp = (this.detail.serial_number ? contentTextarea.length : 0 ) + this.counts.current_equipment_in_order;
    this._changeCountStatus();
  }

  /**
   * initialise the various subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
  private _initSubscriptions(): void {
    const commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.registerSubscription(commentsCountSubscription);

    // Wait for something to indicate that it needs to get the list of equipment locations
    this.registerSubscription(this.equipmentLocationsFetchRequired$
      .pipe(
        // Use the current values from the filter fields
        map(() => [this.logisticsSite, this.warehouse]),
        // Attempt to get the values for the equipment location dropdown
        mergeMap(([logisticsSite, warehouse]: [ITinyLogisticsSite, string]) => defer(() => {
          // If we're missing one of the filter values, return an empty list
          if (!logisticsSite || !warehouse) {
            return of<IEquipmentLocation[]>([]);
          }

          // else we have the filter values, call the API endpoint
          return from(this.apiShiva.equipment_locations
            .list({
              logistics_site__code: logisticsSite?.code,
              warehouse: warehouse,
            }))
            .pipe(map((results: IListResult<IEquipmentLocation>) => results.results));
        })),
      )
      // Assign the resulting list to the list of dropdown options
      .subscribe((equipmentLocations: IEquipmentLocation[]) => this.equipmentLocations$.next(equipmentLocations)));
  }

  private _changeCountStatus(): void {
    if (this.counts.checked_eqp > this.counts.ordered_eqp) {
      this.counts.status = 'danger';
    } else if (this.counts.checked_eqp < this.counts.ordered_eqp) {
      this.counts.status = 'warning';
    } else if (this.counts.checked_eqp === this.counts.ordered_eqp) {
      this.counts.status = 'success';
    }
  }

  private _fetchAvailableEquipmentCount(): void {
    if (this.detail && this.detail.provider_order && this.detail.model) {
      this.apiShiva.provider_orders.available_equipments(this.detail.provider_order.id, this.detail.model.id)
        .then(res => {
          this.counts.updated = true;
          this.counts.ordered_eqp = res['ordered_count'];
        })
        .catch((err) => {
          if (err instanceof WaycomHttpErrorResponse) {
            if (err.getFirstErrorMessage() === 'MISSING_MODEL_ID') {
              this.toastr.error(`Impossible d'effectuer cette action car l'identifiant du modèle n'a pas été reçu par le serveur.`);
              return;
            }
          }
          Promise.reject(err);
        });
    }
  }

  private _fetchRecords(): void {
    if (!this.detail.code) {
      return;
    }

    this.apiShiva.equipment_records.list({equipment__code: this.detail.code})
      .then((res: IListResult<IEquipmentRecord>) => {
        // Generating the comments based on the status
        this.recordList = res['results'].map((item: IEquipmentRecord) => {
          switch (item.status.toLocaleLowerCase()) {
            case 'added':
              item.text = 'Equipement créé.';
              break;
            case 'moved_inventory':
              item.text = 'Retour au stock.';
              break;
            case 'moved_inventory_while_inventory':
              item.text = 'Retour au stock dans le cadre d\'un inventaire';
              break;
            case 'pending':
              item.text = 'Mise en attente durant l\'inventaire';
              break;
            case 'leave_shop':
              item.text = 'Retrait du magasin';
              if (item.entity) {
                item.text += this._createEntityLink(item.entity);
              } else {
                item.text += '.';
              }
              break;
            case 'moved_shop':
              item.text = 'Affectation au magasin';
              if (item.entity) {
                item.text += this._createEntityLink(item.entity);
              } else {
                item.text += '.';
              }
              break;
            case 'reserved':
              if (item.entity) {
                item.text = 'Réservation pour le magasin';
                item.text += this._createEntityLink(item.entity);
              } else {
                item.text = 'Annulation de la réservation.';
              }
              break;
            case 'changed': {
              const owners = item.comment?.split('|') || [];
              owners[0] = owners[0] ? (EQP_OWNERS[owners[0]] || owners[0]) : 'Aucun';
              owners[1] = owners[1] ? (EQP_OWNERS[owners[1]] || owners[1]) : 'Aucun';
              item.text = 'Changement de propriétaire de l\'équipement : ' + owners[0] + ' -> ' + owners[1] + '.';
              break;
            }
            case 'deleted':
              item.text = 'Equipement supprimé.';
              break;
            case 'invoiced':
              item.text = 'Equipement facturé.';
              break;
            case 'zone_updated': {
              const zones: string[] = item.comment?.split('|') || [];
              zones[0] = zones[0] || 'Vide';
              zones[1] = zones[1] || 'Vide';
              item.text = 'Changement de zone : ' + zones[0] + ' -> ' + zones[1] + '.';
              break;
            }
            case 'collecting': {
              item.text = 'A prélever.';
              break;
            }
            default:
              item.text = item.comment;
              break;
          }
          return item;
        });
      });
  }

  private _createEntityLink(entity: IEntity): string {
    return ' <a href="/#/entities/detail/' + entity.code + '">' + entity.code + '</a> ' +
           '(' + entity.name + (entity.customer_ref ? ' / ' + entity.customer_ref : '') + ').';
  }

  private _updateEquipmentModelFilters(): void {
    this.equipementModelFilters = {
      equipment_model_mappings__accounting_equipments__provider_order__id: this.detail.provider_order?.id,
    };
  }

  private _generateRandomSN(quantity: number): void {
    const hexTimestamp = moment().valueOf().toString(16);
    const newSNArray = times(quantity, (i) => `WCMNOSN-${hexTimestamp}${i}`);

    if (this.detail.serial_number) {
      this.detail.serial_number +=  '\n' + newSNArray.join('\n');
    } else {
      this.detail.serial_number = newSNArray.join('\n');
    }
  }

  public onEntityUpdate(newVal: IEntity): void {
    // This function is trigerred only when detail.entity is changed by the user directly from the field
    // and not when the detail.entity is set to null when detail.location changed
    this.detail.entity = newVal;
    if (newVal) {
      this.detail.location = EquipmentLocationEnum.Shop;
    } else if (newVal === null) {
      // user has deliberately cleared the value for 'entity', set the location to 'stock'
      this.detail.location = EquipmentLocationEnum.Stock;
    }
  }

  public save(redirectToCreation?: boolean): void {
    if (!(this.detailForm && this.detailForm.valid) || this.loading) {
      return;
    }
    this.loading = true;
    let promise: Promise<IEquipment | IEquipment[]>;

    // @ts-ignore Typescript complains that this method does not exist pre es2021
    this.detail.serial_number = this.detail.serial_number.replaceAll('\n\n', '\n');

    if (this.detail.mac_address) {
      this.detail.mac_address = this.equipmentsService.cleanMacAddress(this.detail.mac_address);
    }

    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise
      .then((res: IEquipment | IEquipment[]) => {
        const isMultipleEquipments: boolean = isArray<IEquipment>(res) && res.length > 1;
        const equipment: IEquipment = isArray<IEquipment>(res) ? res[0] : res;

        if (!this.detail.code) {
          this.toastr.success(`${isMultipleEquipments ? 'Élements créés' : 'Élement créé'}  avec succès.`);
          this.detail.serial_number = '';
        }

        if (!this.detail.code && redirectToCreation) {
          this.detail.zone = '';
          return this.router.navigateByUrl('/equipments/detail/');
        }

        if (!this.detail.code) {
          // it was a creation
          if (isMultipleEquipments && !this.disableCreateRedirection) {
            // this prevents redirecting after a creation in a modal
            return this.router.navigateByUrl('/equipments/list');
          }

          // Only one equipment item was created, use it as the current equipment
          this.pk = equipment.code;
          this.signalsService.broadcast('equipments:create', equipment.code);
          this._initTabs(equipment);
        }

        this.detail = equipment;
        this._updateBreadcrumbs();
        this.mode = 'normal';
        this._refreshEquipmentLocationFields(equipment);

        // fetching the records
        this._fetchRecords();
        this._updateEquipmentModelFilters();
        this.modeChanged.emit(this.mode);
        this.detailSaved.emit(this.detail);
      })
      .catch((err) => this._handleSaveError(err))
      .finally(() => this.loading = false);
  }

  private _handleSaveError(err: Error): void {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.detail === 'DUPLICATE_SERIAL_NUMBERS') {
        let errMsg = 'Erreur lors de la création des équipements. Aucun équipement n\'a été créé.<br>';
        errMsg += 'Les numéros de série suivants existent déjà :';
        errMsg += '<ul>';
        err.context['duplicate_sn'].forEach((sn: string[]) => {
          errMsg += `<li>${sn}</li>`;
        });
        errMsg += '</ul>';
        void this.wcmModalsService.alert('Numéro de série déjà existant', errMsg);
        return;

      } else if (err.detail === 'DUPLICATE') {
        const errMsg = 'Un equipment avec le même numéro de série existe déjà.';
        void this.wcmModalsService.alert('Numéro de série déjà existant', errMsg);
        return;

      } else if (err.getFirstErrorMessage() === 'ZONE_ONLY_FOR_LOCATION_STOCK') {
        this.toastr.error(`Impossible de donner un zonage si l'équipement n'est pas en stock.`);
        return;
      }
    }

    // error not handled locally, bubble up to generic handler
    Promise.reject(err);
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: IEquipment) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
        this._refreshEquipmentLocationFields(res);

        // fetching the records
        this._fetchRecords();
        this._updateEquipmentModelFilters();

      })
      .catch(() => {})
      .finally(() => this.loading = false);
  }

  private _refreshEquipmentLocationFields(detail: IEquipment): void {
    // Set the initial field values
    this.logisticsSite = detail?.equipment_location?.logistics_site;
    this.warehouse = detail?.equipment_location?.warehouse;

    // Trigger the equipment location list api call to get the list of available values
    this.equipmentLocationsFetchRequired$.next();
  }

  private _resetEquipmentLocation(): void {
    // Reset the equipment location information because we changed the filter value and got a different list of equipment locations
    this.detail.equipment_location = null;
  }

  private _initTabs(detail: IEquipment): void {
    // If any tab filter must be initialized, it's done here
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail && this.detail.entity && this.detail.entity.code) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: '/entities/detail/' + this.detail.entity.code,
        after: '>'}
      );
    }

    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/equipments/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}


