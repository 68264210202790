import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';

import { LogisticsSitesModule } from '@views/logistics-sites/logistics-sites.module';
import { TagsModule } from '@views/tags/tags.module';

import { EntityEquipmentsHistoryModalComponent } from './entity-equipments-history-modal/entity-equipments-history-modal.component';
import { EquipmentFieldComponent } from './equipment-field.component';
import { EquipmentsBulkAssignModalComponent } from './equipments-bulk-assign-modal.component';
import { EquipmentsBulkDeleteErrorModalComponent } from './equipments-bulk-delete-error-modal.component';
import { EquipmentsDetailComponent } from './equipments-detail.component';
import { EquipmentsDetailNoSerialNumberModalComponent } from './equipments-detail-no-serial-number-modal.component';
import { EquipmentsInventoryComponent } from './equipments-inventory/equipments-inventory.component';
import { EquipmentsListBulkPatchTagsModalComponent } from './equipments-list-bulk-patch-tags-modal.component';
import { EquipmentsListComponent } from './equipments-list.component';
import { EquipmentsListLogisticsComponent } from './equipments-list-logistics.component';
import { EquipmentsMacAddrModalComponent } from './equipments-mac-addr-modal.component';
import { EquipmentsModalComponent } from './equipments-modal.component';
import { EquipmentsNetworkDevicesHistoryComponent } from './equipments-network-devices-history.component';
import { EquipmentsRouteComponent } from './equipments-route.component';
import { EquipmentsSelectModalComponent } from './equipments-select-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    LogisticsSitesModule,
    TagsModule,
  ],
  declarations: [
    EntityEquipmentsHistoryModalComponent,
    EquipmentFieldComponent,
    EquipmentsBulkAssignModalComponent,
    EquipmentsBulkDeleteErrorModalComponent,
    EquipmentsDetailComponent,
    EquipmentsDetailNoSerialNumberModalComponent,
    EquipmentsInventoryComponent,
    EquipmentsListBulkPatchTagsModalComponent,
    EquipmentsListComponent,
    EquipmentsListLogisticsComponent,
    EquipmentsMacAddrModalComponent,
    EquipmentsModalComponent,
    EquipmentsNetworkDevicesHistoryComponent,
    EquipmentsRouteComponent,
    EquipmentsSelectModalComponent,
  ],
  exports: [
    EquipmentFieldComponent,
    EquipmentsBulkAssignModalComponent,
    EquipmentsBulkDeleteErrorModalComponent,
    EquipmentsListBulkPatchTagsModalComponent,
    EquipmentsListComponent,
    EquipmentsListLogisticsComponent,
    EquipmentsMacAddrModalComponent,
    EquipmentsModalComponent,
    EquipmentsNetworkDevicesHistoryComponent,
    EquipmentsRouteComponent,
    EquipmentsSelectModalComponent,
  ],
})
export class EquipmentsModule {}
