import { Component, Input, Injector, ViewChild, OnInit } from '@angular/core';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { EQUIPMENT_LOCATION_WAREHOUSES } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { omit } from '@core/helpers';
import { IEquipmentLocation, ILogisticsSite } from '@core/interfaces';
import { ObjectToolService } from '@core/services/object-tool.service';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-equipment-location-list',
  templateUrl: './equipment-location-list.component.html'
})
export class EquipmentLocationListComponent extends GenericListComponent implements OnInit {

  @ViewChild('wcmTable', { static: true }) public wcmTable: WcmTableComponent;

  @Input() public logisticsSite: ILogisticsSite;

  public api: ApiShivaService['equipment_locations'];
  public readonly warehouses: Record<string, string> = EQUIPMENT_LOCATION_WAREHOUSES;

  public loadingItemAction = false;
  public editionInProgress = false;

  constructor(
    public readonly injector: Injector,
    private readonly wcmModalsService: WcmModalsService,
    private readonly objectToolService: ObjectToolService,
    private readonly signalsService: SignalsService,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.localFilters = {
      logistics_site__code: this.logisticsSite.code,
    };
    this.api = this.apiShiva.equipment_locations;
  }

  public addEquipmentLocation(): void {
    if (this.editionInProgress) {
      return;
    }
    const emptyRow: IEquipmentLocation = {
      id: null,
      number: null,
      warehouse: null,
      logistics_site: {
        code: this.logisticsSite.code,
      },
      editable: true,
    };
    this.wcmTable.items.push(emptyRow);
    this.editionInProgress = true;
  }

  public edit(item: IEquipmentLocation): void {
    // doing an item back up to be able to restore it if the user cancel the edition
    item.backup = { ...item };
    item.editable = true;
    this.editionInProgress = true;
  }

  /**
   * If the item has no backup, it's from a creation, we just remove it from the list,
   *  otherwise, restoring the backup to cancel the edition and bring back the previous values.
   */
  public cancelEdit(item: IEquipmentLocation): void {
    if (!item.backup) {
      this.wcmTable.items.pop();
    } else {
      const backup: IEquipmentLocation = { ...item.backup };
      this.objectToolService.replaceObjContent(item, backup);
    }
    this.editionInProgress = false;
  }

  public confirmDelete(item: IEquipmentLocation): void {
    this.wcmModalsService.confirm(
      `Suppression d'un emplacement`,
      `Confirmez-vous la suppression de cet emplacement ?`,
      'Confirmer',
      'Annuler'
    )
      .then(
        () => this._delete(item),
        () => {}
      );
  }

  public save(item: IEquipmentLocation): void {
    this.loadingItemAction = true;
    // removing the object attributes that we use locally for the edition
    const payload: IEquipmentLocation = omit(item, 'editable', 'backup') as IEquipmentLocation;

    let promise: Promise<IEquipmentLocation>;
    if (item.id) {
      promise = this.api.update(item.id, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise
      .then(() => {
        this.wcmTable.refreshTable();
        this.editionInProgress = false;
        this.signalsService.broadcast('model-history-list-refresh');
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingItemAction = false);
  }

  /**
   * In case the user was adding a line before filtering / ordering the table,
   *  we reset the edition state to prevent disabling the action buttons forever !
   */
  public fetchCallback(): void {
    this.editionInProgress = false;
  }

  private _delete(item: IEquipmentLocation): void {
    this.loadingItemAction = true;
    this.api.delete(item.id)
      .then(() => this.wcmTable.refreshTable())
      .catch((err: unknown) => {
        console.error(err);
        this.toastr.error(`Erreur lors de la suppression de l'objet. Veuillez essayer à nouveau.`);
      })
      .finally(() => this.loadingItemAction = false);
  }
}
